* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}
#root {
  height: 100%;
}
.App {
  height: 100vh;
  background-color: black;
}
