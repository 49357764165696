@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200&display=swap");

/* * {

} */

/* html {
  font-size: 55.5%;
} */

:root {
  --main-color: #000000;
  --primary-color: #000000;
  --icon-color: #000000;
}

i {
  width: 2rem;
  height: 2rem;
}

.fa,
.fas {
  font-weight: 900;
  display: grid;
  place-items: center;
  font-size: 0.888rem;
  cursor: pointer;
}

.carts {
  width: 80%;
  height: 100vh;
  margin: auto;
  /* margin: 0;
  padding: 0; */
  box-sizing: border-box;
  font-family: Mulish;
  /* font-size: x-small; */
}

header {
  width: 100%;
  line-height: 10rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 0.1rem solid #9a9191;
  transform: rotate(-0.05deg);
  margin-bottom: 7rem;
}

.continue-shopping {
  display: flex;
  height: inherit;
  align-items: center;
}

.continue-shopping .arrow-icon {
  width: 2.2rem;
  height: 2.2rem;
}

.continue-shopping h3 {
  font-size: 1.11rem;
  text-transform: capitalize;
  color: white;
  margin-left: 1.5rem;
  font-weight: 400;
}

.cart-icon {
  display: flex;
  text-align: end;
  margin-right: 2rem;
  align-items: center;
  position: relative;
  justify-content: flex-end;
}

.cart-icon img {
  width: 4rem;
  height: 4rem;
  color: #2f80ed;
}

.cart-icon p {
  position: absolute;
  width: 3rem;
  height: 3rem;
  right: -1.2rem;
  top: 0.9rem;
  border-radius: 50%;
  background: #99cbf7;
  color: #fff;
  box-sizing: border-box;
  font-size: 0.88rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.main-cart-section h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 1.665rem;
  text-transform: capitalize;
  color: rgb(225, 220, 220);
  margin-bottom: 1rem;
}

.main-cart-section .total-items {
  font-style: normal;
  font-weight: normal;
  font-size: 0.999rem;
  text-transform: capitalize;
  color: white;
  margin-bottom: 5rem;
}

.total-items-count {
  font-weight: bold;
  color: white;
}

/* cart main div start  */

.main-cart-section .cart-items {
  width: 100%;
  height: 62rem;
  background: linear-gradient(
    103.49deg,
    #ffffff -28.13%,
    rgba(242, 247, 255, 0.5) 116.84%
  );
  box-shadow: rgba(0, 0, 0, 0.08) 0rem 0.4rem 1.2rem;
  border-radius: 2rem;
  margin: auto;
  margin: auto;
  display: grid;
  place-items: center;
}

.cart-items hr {
  margin: 1rem auto;
  overflow-y: scroll;
}

.cart-items-container {
  width: 90%;
  height: 56rem;
}

.items-info {
  width: 100%;
  height: 6rem;
  /* background-color: lavender; */
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.items-info .product-img img {
  width: 10rem;
  height: 6rem;
  filter: drop-shadow(0rem 0.4rem 1rem #f1f7ff);
  border-radius: 1rem;
}

.items-info .title {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.items-info .title h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 1.221rem;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: rgb(0, 0, 0);
}

.items-info .title p {
  font-style: normal;
  font-weight: normal;
  font-size: 0.999rem;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  text-align: left;
  color: var(--primary-color);
}

.add-minus-quantity {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.add-minus-quantity button {
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

.add-minus-quantity input {
  width: 6rem;
  height: 3rem;
  border: 0.141rem solid var(--primary-color);
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  font-size: 0.888rem;
  text-align: center;
  text-transform: capitalize;
  color: var(--primary-color);
  margin: 0 1rem;
  border-radius: 0.5rem;
  outline: none;
  background-color: transparent;
}

.add-minus-quantity input:focus {
  outline: none;
}

.price {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.price h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 1.11rem;
  text-transform: capitalize;
  color: var(--main-color);
}

.remove-item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 5rem;
}

.remove-item button {
  border: none;
  background-color: transparent;
}

.card-total {
  width: 95%;
  margin-top: 4rem;
  text-align: right;
}

.card-total h3 {
  font-style: 200;
  font-size: 1.4319rem;
  line-height: 3.2rem;
  text-transform: capitalize;
  color: rgb(189, 192, 205);
}

.card-total h3 span {
  font-style: normal;
  font-weight: bold;
  font-size: 1.554rem;
  line-height: 3.2rem;
  text-transform: capitalize;
  color: #ffe2e2;
  margin-left: 1rem;
}

.card-total button {
  border: none;
  font-size: 0.888rem;
  padding: 1rem 3rem;
  color: #000000;
  background-color: #349bf3;
  text-transform: uppercase;
  margin-top: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  letter-spacing: 1px;
}

/* hover effects  */
.fa-trash-alt {
  color: #ed4848;
}

.fa-plus:hover {
  color: #42c157;
}

.fa-minus:hover {
  color: #ffb800;
}

.fa-plus,
.fa-minus {
  color: var(--icon-color);
}

.card-total button:hover {
  background-color: #0077dc;
}

.card-total .clear-cart {
  margin-left: 3rem;
  background-color: rgb(209, 61, 61);
}

.card-total .clear-cart:hover {
  background-color: rgb(197, 5, 5);
}

/* responsive media queries  */

@media (max-width: 968px) {
  html {
    font-size: 50%;
  }
}

@media (max-width: 768px) {
  .continue-shopping h3 {
    margin-left: 0;
    font-size: 1.02675rem;
  }

  .items-info {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
  }

  .title,
  .add-minus-quantity,
  .price,
  .remove-item {
    padding-left: 2rem;
  }

  .items-info .product-img {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
  }

  .add-minus-quantity {
    margin: 2rem 0 2rem 0;
    justify-content: flex-start;
  }

  .price {
    justify-content: flex-start;
    margin-bottom: 2rem;
  }

  .price h3::before {
    content: "Price: ";
  }

  .remove-item {
    justify-content: flex-start;
  }

  .card-total {
    margin-bottom: 2rem;
    text-align: center;
  }

  .card-total button {
    margin-bottom: 3rem;
  }
}
